/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { DefaultLabel, mathDecimal } from '@shared/util/code';
import {
  Aggregation,
  QuestionnaireType,
  ReportData,
  ReportMetaData,
  ReportOutputDefinitionModel,
  ReportVariable,
  ReportVariableCounts,
  SpssVariableType,
} from '../../../core/webapi';
import { ISelectedQuestion } from '../../../shared/report-types';

export type Serie = {
  data: number[];
  name: string;
  n: number;
};

export type SomeHighchartsOptions = {
  title: string;
  subtitle: string;
  categoryLabels: string[];
  series: Serie[];
  aggregation: Aggregation;
  remark: string;
};

@Injectable({
  providedIn: 'root',
})
export class HighchartDataService {
  getData(
    reportDataArray: ReportData[],
    reportMetaData: ReportMetaData,
    categoryCaseFilter: string[],
    locationFilterLabel: string,
    selectedGroups: Record<number, string[]> | undefined = undefined,
    extraSubTitle: string | undefined = undefined,
    questionnaireType: QuestionnaireType | undefined = undefined,
  ): Record<number, SomeHighchartsOptions> {
    const highchartData: Record<number, SomeHighchartsOptions> = {};
    reportDataArray.forEach((reportData, index) => {
      const variableId = reportData.reportVariableId;
      const orderId = reportData.order;
      const variable = reportMetaData.reportVariables.filter((x): x is ISelectedQuestion => x.variableId === reportData.reportVariableId)[0];
      const subtitle = this.getSubtitle(selectedGroups ? selectedGroups![index] : undefined, categoryCaseFilter, extraSubTitle);
      const remark = this.getRemark(reportMetaData, variable);
      const categoryLabels = variable.categories.map((x) => x.description!);
      const outputDefinition = reportMetaData.reportOutputDefinitions.find((x) => x.variable === variable.variableName);
      const aggregation = this.getAggregation(outputDefinition, variable);

      for (const [outerGroupName, innerGroup] of Object.entries(reportData.reportGroups)) {
        const outerLegendName = outerGroupName.startsWith(DefaultLabel) ? outerGroupName.replace(DefaultLabel, locationFilterLabel) : outerGroupName;

        for (const [innerGroupName, variableCounts] of Object.entries(innerGroup)) {
          const innerLegendName = innerGroupName.startsWith(DefaultLabel)
            ? innerGroupName.replace(DefaultLabel, locationFilterLabel)
            : innerGroupName;
          const categoryData = this.getCategoryData(variableCounts, aggregation, variable, questionnaireType);
          if (!(orderId in highchartData)) {
            highchartData[orderId] = {
              categoryLabels,
              title: variable.variableDescription,
              subtitle,
              remark,
              series: [],
              aggregation: aggregation,
            };
          }
          if (
            !reportMetaData.reportVariables.filter((x) => x.variableId === +variableId)[0].isMultiResponse &&
            variableCounts.respondentCount !== -1 &&
            aggregation === Aggregation.None
          ) {
            this.roundCategoryData(categoryData, variableCounts, variable);
          }
          const legendName = `${outerLegendName} ${innerLegendName} ${this.getLegendSuffix(variableCounts, questionnaireType)} `;
          highchartData[orderId].series.push({
            data: categoryData,
            n: variableCounts.respondentCount === -1 ? 0 : variableCounts.respondentCount,
            name: legendName,
          });
        }
      }
    });
    return highchartData;
  }

  private getLegendSuffix(variableCounts: ReportVariableCounts, questionnaireType: QuestionnaireType | undefined): string {
    if (questionnaireType === QuestionnaireType.PoStudent || questionnaireType === QuestionnaireType.VoStudent) {
      return variableCounts.respondentCount < 3 ? '<br>Onvoldoende respons' : `(n&nbsp;=&nbsp;${variableCounts.respondentCount})`;
    }
    return `(n&nbsp;=&nbsp;${variableCounts.respondentCount === -1 ? '0' : variableCounts.respondentCount})`;
  }

  private roundCategoryData(categoryData: number[], variableCounts: ReportVariableCounts, variable: ISelectedQuestion) {
    const n = variableCounts.respondentCount;
    const categoryCounts = variableCounts.categoryCounts;
    const categoryDataUnRounded = variable.categories.map((x) => (n ? (100.0 * categoryCounts[x.categoryId].count) / n : 0));
    this.roundToHundred(categoryData, categoryDataUnRounded);
  }

  private getCategoryData(
    variableCounts: ReportVariableCounts,
    aggregation: Aggregation,
    variable: ISelectedQuestion,
    questionnaireType: QuestionnaireType | undefined,
  ): number[] {
    const n =
      (questionnaireType === QuestionnaireType.PoStudent || questionnaireType === QuestionnaireType.VoStudent) && variableCounts.respondentCount < 3
        ? -1
        : variableCounts.respondentCount;
    const categoryCounts = variableCounts.categoryCounts;
    const categoryData =
      n < 1
        ? variable.categories.map(() => 0) //geen of onvoldoende respons(-1)
        : aggregation === Aggregation.None
          ? variable.categories.map((x) => Math.round((100.0 * categoryCounts[x.categoryId].count) / n))
          : aggregation === Aggregation.Average
            ? variable.categories.map(() => mathDecimal.round(Object.values(categoryCounts)[0].sum / n, 2))
            : variable.categories.map(() => Object.values(categoryCounts)[0].sum);
    return categoryData;
  }

  private getAggregation(outputDefinition: ReportOutputDefinitionModel | undefined, variable: ISelectedQuestion): Aggregation {
    const aggregation = outputDefinition ? outputDefinition.aggregation : Aggregation.None;
    return variable.variableType === SpssVariableType.Number && aggregation === Aggregation.None ? Aggregation.Average : aggregation;
  }

  private getSubtitle(selectedGroups: string[] | undefined, categoryCaseFilter: string[], extraSubTitle: string | undefined) {
    let subtitle = '';
    if (selectedGroups?.length) {
      subtitle += 'Resultaten per: ';
    }
    if (selectedGroups && selectedGroups.length) {
      subtitle += selectedGroups.join(', ');
    }
    if (categoryCaseFilter.length) {
      const categories = categoryCaseFilter.join(', ');
      if (categories) {
        subtitle += '<br>Case-selectie: ' + categories;
      }
    }
    if (extraSubTitle) {
      subtitle += `<br>${extraSubTitle}`;
    }
    return subtitle;
  }

  private roundToHundred(categoryData: Array<number>, categoryDataUnRounded: Array<number>) {
    const sum = categoryData.reduce((partialSum, x) => partialSum + x, 0);
    if (sum !== 0) {
      if (sum > 100) {
        const difference = sum - 100;
        const categoryDataFloor = categoryDataUnRounded.map((number) => number - Math.floor(number) - 0.5);
        const index = this.getClosestNumberToZeroIndex(categoryDataFloor);
        categoryData[index] = categoryData[index] - difference;
      }
      if (sum < 100) {
        const difference = 100 - sum;
        const categoryDataFloor = categoryDataUnRounded.map((number) => 0.5 - (number - Math.floor(number)));
        const index = this.getClosestNumberToZeroIndex(categoryDataFloor);
        categoryData[index] = categoryData[index] + difference;
      }
    }
  }

  private getClosestNumberToZeroIndex(numbers: Array<number>): number {
    const closest = this.getPositiveNumberClosestToZero(numbers);
    return numbers.findIndex((x) => x === closest);
  }

  private getPositiveNumberClosestToZero(numbers: Array<number>) {
    if (numbers.length === 0) {
      return 0;
    }
    let closest = numbers[0];

    numbers.forEach((number) => {
      const absNumber = Math.abs(number);
      const absClosest = Math.abs(closest);
      if (absNumber < absClosest) {
        closest = number;
      } else if (absNumber === absClosest && closest < 0) {
        closest = number;
      }
    });
    return closest;
  }

  private getRemark = (reportMetaData: ReportMetaData, variable: ReportVariable) =>
    variable.remark ?? reportMetaData.reportOutputDefinitions.find((x) => x.variable === variable.variableName)?.remark ?? '';
}
