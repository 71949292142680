import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { Aggregation } from '@shared/data-access/reports';
import * as Highcharts from 'highcharts';
import {
  ExportingOptions,
  OptionsChartZoomingTypeValue,
  PlotOptions,
  SeriesOptionsType,
  SubtitleOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
// eslint-disable-next-line @typescript-eslint/naming-convention
import HC_exporting from 'highcharts/modules/exporting';
import { hasAnimation, HIGHCHARTS, HighchartsThemeService } from '../index';

HC_exporting(Highcharts);
export type LineChartSerie = {
  type: string;
  data: unknown;
  name: string;
};

export type LineChartOptions = {
  plotOptions?: PlotOptions;
  height?: number | `${number}%`;
  title?: string;
  subtitle?: string | SubtitleOptions;
  yAxis?: YAxisOptions[];
  xAxis?: XAxisOptions[];
  zooming?: { type: OptionsChartZoomingTypeValue };
  colors?: string[];
  series: LineChartSerie[];
  aggregation: Aggregation;
  exporting?: ExportingOptions;
  remark?: string;
  decimals: number;
};

@Component({
  selector: 'lsu-charts-line-chart',
  templateUrl: './line-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HighchartsChartModule, TranslocoModule],
})
export class LineChartComponent {
  chartOptions: Highcharts.Options = {};
  highcharts: typeof Highcharts = HIGHCHARTS;
  updateFlag = false;
  remark!: string;
  @Input() maxHeight!: number;

  @Input() set print(isPrint: boolean) {
    this._print = isPrint;
  }

  @Input() set data(data: LineChartOptions | undefined) {
    if (data) {
      this.remark = data.remark ?? '';
      this.setChartOptions(data);
    }
  }

  @Output() rendered = new EventEmitter<Highcharts.Chart>();

  private _print = false;

  constructor(highchartsThemeService: HighchartsThemeService) {
    highchartsThemeService.applyThemeOptions();
  }

  onRender(chart: Highcharts.Chart) {
    this.rendered.emit(chart);
  }

  setChartOptions(data: LineChartOptions) {
    this.chartOptions = {
      chart: {
        plotBackgroundColor: undefined,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: 'transparent',
      },
    };

    this.chartOptions.exporting = {
      enabled: data.exporting && !this._print,
    };

    this.chartOptions.plotOptions = {
      ...data.plotOptions,
      ...{ series: { animation: hasAnimation(this._print, Highcharts.getOptions().plotOptions?.series?.animation) } },
    };
    this.chartOptions.series = data.series as SeriesOptionsType[];

    if (data.xAxis) {
      this.chartOptions.xAxis = data.xAxis;
    }
    if (data.yAxis) {
      this.chartOptions.yAxis = data.yAxis;
    }
    if (data.height) {
      this.chartOptions.chart!.height = data.height;
    }
    if (data.zooming) {
      this.chartOptions.chart!.zooming = data.zooming;
    }
    if (data.colors) {
      this.chartOptions.colors = data.colors;
    }
    if (data.title) {
      this.chartOptions.title = { text: data.title };
    }
    if (data.subtitle) {
      this.chartOptions.subtitle = typeof data.subtitle === 'string' ? { text: data.subtitle } : data.subtitle;
    }

    setTimeout(() => {
      this.updateFlag = true;
    }, 1000);
  }
}
