import * as Highcharts from 'highcharts';

export const HIGHCHARTS: typeof Highcharts = Highcharts;

export * from './area-chart/area-chart.component';
export * from './column-bar-chart/column-bar-chart.component';
export * from './core/animation';
export * from './core/high-chart-data.service';
export * from './core/highcharts-theme.service';
export * from './core/spectrum.class';
export * from './line-chart/line-chart.component';
export * from './pie-chart/pie-chart.component';

// @TODO(Standalone components) import this const, in all projects which depend on these components
// this can be done when this issue is fixed
// https://github.com/angular/angular/issues/48089

// export const SharedUiCharts = [AreaChartComponent, LineChartComponent, PieChartComponent, VerticalStackedBarChartComponent] as const;
