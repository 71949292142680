import Rainbow from 'rainbowvis.js';

export function getSpectrum(colors: string[], length: number) {
  return new SpectrumClass({
    colors,
    range: {
      start: 0,
      end: length,
    },
  });
}

export type SpectrumOptions = {
  colors: string[];
  range: {
    start: number;
    end: number;
  };
};

export class SpectrumClass {
  spectrum: Rainbow;
  length;

  constructor(options: SpectrumOptions) {
    this.spectrum = new Rainbow().setSpectrumByArray(options.colors);
    this.length = options.range.end + 1 - options.range.start;
    this.spectrum.setNumberRange(options.range.start, options.range.end);
  }

  getColorAt(index: number) {
    return `#${this.spectrum.colorAt(index)}`;
  }
}
