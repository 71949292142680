<highcharts-chart
  [Highcharts]="highcharts"
  [options]="chartOptions"
  (chartInstance)="onRender($event)"
  [(update)]="updateFlag"
  class="d-block w-99"
></highcharts-chart>
<div class="d-flex justify-content-center">
  <div [innerHtml]="remark"></div>
</div>
