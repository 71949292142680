import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import { TranslocoModule } from '@jsverse/transloco';
import { Aggregation } from '@shared/data-access/reports';
import { isType } from '@shared/util/code';
import * as Highcharts from 'highcharts';
import {
  CaptionOptions,
  Chart,
  ExportingOptions,
  LegendOptions,
  Options,
  PlotOptions,
  SeriesPieOptions,
  SubtitleOptions,
  TitleOptions,
  TooltipOptions,
} from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
// eslint-disable-next-line @typescript-eslint/naming-convention
import HC_exporting from 'highcharts/modules/exporting';
import { hasAnimation, HIGHCHARTS, HighchartsThemeService } from '../index';

HC_exporting(Highcharts);

export type PieChartOptions = {
  colors?: string[];
  plotOptions?: PlotOptions;
  height?: number | `${number}%`;
  semiCircle?: boolean;
  caption?: CaptionOptions;
  title?: TitleOptions;
  subtitle?: SubtitleOptions;
  legend?: LegendOptions;
  tooltip?: TooltipOptions;
  series: SeriesPieOptions[];
  aggregation: Aggregation;
  exporting?: ExportingOptions;
  remark?: string;
  decimals: number;
};

@Component({
  selector: 'lsu-charts-pie-chart',
  templateUrl: './pie-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HighchartsChartModule, TranslocoModule],
})
export class PieChartComponent {
  chartOptions: Options = {};
  highcharts: typeof Highcharts = HIGHCHARTS;
  updateFlag = false;
  remark!: string;
  @Input() maxHeight!: number;

  @Input() set print(isPrint: boolean) {
    this._print = isPrint;
  }

  @Input() set data(data: PieChartOptions | undefined) {
    if (data) {
      this.remark = data.remark ?? '';
      this.setChartOptions(data);
    }
  }

  @Output() rendered: EventEmitter<Chart> = new EventEmitter<Chart>();

  private _print = false;
  constructor(highchartsThemeService: HighchartsThemeService) {
    highchartsThemeService.applyThemeOptions();
    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
    });
  }

  onRender(chart: Chart) {
    this.rendered.emit(chart);
  }

  setChartOptions(data: PieChartOptions) {
    this.chartOptions = {
      chart: {
        plotBackgroundColor: undefined,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: 'transparent',
      },
    };

    this.chartOptions.exporting = {
      enabled: isType(data.exporting, 'enabled') && data.exporting.enabled && !this._print,
    };

    if (data.height) {
      this.chartOptions.chart!.height = data.height;
    }
    this.chartOptions.plotOptions = {
      ...data.plotOptions,
      ...{ series: { animation: hasAnimation(this._print, Highcharts.getOptions().plotOptions?.series?.animation) } },
    };
    this.chartOptions.series = data.series;

    if (data.semiCircle) {
      this.chartOptions.plotOptions!.pie!.startAngle = -90;
      this.chartOptions.plotOptions!.pie!.endAngle = 90;
    }

    if (data.legend) {
      this.chartOptions.legend = data.legend;
    }

    if (data.tooltip) {
      this.chartOptions.tooltip = data.tooltip;
    }
    if (data.caption) {
      this.chartOptions.caption = data.caption;
    }

    if (data.colors) {
      this.chartOptions.colors = data.colors;
    }

    this.chartOptions.title = data.title;
    this.chartOptions.subtitle = data.subtitle;

    setTimeout(() => {
      this.updateFlag = true;
    }, 1000);
  }
}
