import { SeriesOptionsType } from 'highcharts';

export function getColorScheme(series: SeriesOptionsType[]): string[] {
  if (!series.length) {
    return [];
  }

  const negatives = [
    'nee',
    'niet',
    'nooit',
    'geen',
    'nee, niet meer',
    'minder dan één keer per week',
    'een paar keer per jaar',
    'minder dan de helft',
    'geen enkele medewerker',
    'vervelend',
    'niet zo leuk',
    'oneens',
    'niet van toepassing',
    'helemaal mee oneens',
    'niet van toepassing',
    'nee, dat gebeurt niet',
    'minder dan 3 stelling beantwoord als enthousiaste pedagogisch medewerker',
    'minder dan 3 stelling beantwoord als leesbevorderende pedagogisch medewerker',
    'minder dan 4 stellingen positief beantwoord betekent: niet leesbevorderend',
    'minder dan 3 stellingen beantwoord als leesbevorderende leerkracht',
    'op geen enkele groep',
    'oneens',
    'slecht',
    'weet ik niet',
    'weet niet',
    'nog niet',
    'mee oneens',
    'helemaal niet leesbevorderend',
    'helemaal niet enthousiast',
    '0 (helemaal niet leesbevorderend)',
    '0 (helemaal niet enthousiast)',
  ];

  let colors = ['#006b00', '#00cd00', '#FFFF00', '#FFD580', '#cd6e00', '#cd0000'];

  if (series.length === 1) {
    colors = ['#418cf0'];
  }

  if (series.length === 2) {
    colors = ['#00cd00', '#cd0000'];
  }
  if (series.length === 3) {
    colors = ['#00cd00', '#FFFF00', '#cd0000'];
  }
  if (series.length === 4) {
    colors = ['#00cd00', '#FFFF00', '#cd6e00', '#cd0000'];
  }
  if (series.length === 5) {
    colors = ['#006b00', '#00cd00', '#FFFF00', '#cd6e00', '#cd0000'];
  }
  if (series.length === 6) {
    colors = ['#006b00', '#00cd00', '#c4ff00', '#FFFF00', '#cd6e00', '#cd0000'];
  }
  if (series.length === 7) {
    colors = ['#006b00', '#00cd00', '#c4ff00', '#f5e00f', '#FFD580', '#cd6e00', '#cd0000'];
  }
  if (series.length === 8) {
    colors = ['#035103', '#028a02', '#00cd00', '#c4ff00', '#f5e00f', '#FFD580', '#cd6e00', '#cd0000'];
  }
  if (series.length === 9) {
    colors = ['#035103', '#028a02', '#00cd00', '#c4ff00', '#f5e00f', '#FFD580', '#cd6e00', '#d20b40', '#cd0000'];
  }
  if (series.length === 10) {
    colors = ['#035103', '#028a02', '#00cd00', '#c4ff00', '#f5e00f', '#FFD580', '#fcac5b', '#cd6e00', '#d20b40', '#cd0000'];
  }

  // if positive and negative serie
  if (series.some((serie) => negatives.includes(serie.name!.toLowerCase().trim()))) {
    colors = negatives.includes(series[0].name!.toLowerCase().trim()) ? colors.reverse() : colors;
  } else {
    colors = ['#2f7ed8', '#0d233a', '#8bbc21', '#1aadce', '#ea27c2', '#f28f43', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
  }

  colors = setDeviatingColor(colors, series);

  return colors;
}

function setDeviatingColor(colors: string[], series: SeriesOptionsType[]): string[] {
  const deviations = ['n.v.t.', 'n.v.t', 'nvt', 'anders', 'weet niet', 'weet ik niet', 'niet van toepassing'];
  const deviatingIndex = series.findIndex((serie) => deviations.includes(serie.name!.toLowerCase().trim()));
  const deviatingColor = '#6E6E6E';

  if (deviatingIndex !== -1) {
    const currentColor = colors[deviatingIndex];
    colors[deviatingIndex] = deviatingColor;
    if (deviatingIndex === 0) {
      // set color on first position to second position
      colors[1] = currentColor;
    }
    if (colors[deviatingIndex - 1]) {
      // set color on last position to second-last
      colors[deviatingIndex - 1] = currentColor;
    }
  }

  return colors;
}
