import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HIGHCHARTS, HighchartsThemeService } from '@shared/ui/charts';
import * as Highcharts from 'highcharts';
import { XAxisOptions, YAxisOptions } from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { Aggregation } from '../../../../core/webapi';
import { getColorScheme } from '../color-scheme';
import { SomeHighchartsOptions } from '../highchart-data.service';

@Component({
  selector: 'pma-report-chart-column',
  templateUrl: './chart-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HighchartsThemeService],
  standalone: true,
  imports: [HighchartsChartModule],
})
export class ChartColumnComponent {
  readonly chartOptions: Highcharts.Options;
  readonly highcharts: typeof Highcharts = HIGHCHARTS;
  updateFlag = false;
  chart?: Highcharts.Chart;
  height = 600;
  remark!: string;

  @Input() maxHeight?: number;

  @Input() set print(isPrint: boolean) {
    this._print = isPrint;
  }

  @Input() set data(data: SomeHighchartsOptions) {
    this.remark = data.remark;
    this.setChartOptions(data);
  }

  @Input() set reflow(trigger: boolean) {
    if (this.chart) {
      this.chart.reflow();
    }
  }

  @Output() rendered: EventEmitter<Highcharts.Chart> = new EventEmitter<Highcharts.Chart>();

  private _print = false;

  constructor(highchartsThemeService: HighchartsThemeService) {
    highchartsThemeService.applyThemeOptions();
    this.chartOptions = {
      legend: {
        width: '100%',
      },
      chart: {
        type: 'column',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
          },
        },
      },
    };
  }

  onRender(chart: Highcharts.Chart) {
    this.chart = chart;
    this.rendered.emit(chart);
  }

  setChartOptions(data: SomeHighchartsOptions) {
    this.chartOptions.chart!.height = this.getChartHeight(data);
    this.chartOptions.xAxis = this.xAxis(
      data.series.map((x) => x.name),
      data.series.length,
    );
    this.chartOptions.yAxis = this.yAxis(data.aggregation);
    this.chartOptions.series = data.categoryLabels.map((x, i) => ({
      type: 'column',
      showInLegend: true,
      name: x,
      data: data.series.map((y) => y.data[i]),
      animation: this._print ? false : true,
    }));

    this.chartOptions.exporting = {
      enabled: this._print ? false : true,
    };

    this.chartOptions.title = { text: data.title, widthAdjust: -100 };
    this.chartOptions.subtitle = { text: data.subtitle };
    const series = this.chartOptions.series;
    this.highcharts.getOptions().colors = getColorScheme(series);

    setTimeout(() => {
      this.updateFlag = true;
    }, 1000);
  }

  xAxis(categories: string[], numberOfSeries: number): XAxisOptions {
    const options = { categories } as XAxisOptions;
    if (this._print && numberOfSeries > 10) {
      options.labels = {
        rotation: 290,
      };
    }
    return options;
  }

  yAxis(aggregation: Aggregation): YAxisOptions {
    return {
      min: 0,
      title: {
        text: aggregation === Aggregation.Average ? 'Gemiddeld aantal' : aggregation === Aggregation.Sum ? 'Totaal aantal' : 'Totaal percentage',
      },
      labels: {
        format: aggregation !== Aggregation.None ? '{value}' : '{value}%',
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title?.style && Highcharts.defaultOptions.title.style.color) || 'gray',
        },
      },
    };
  }

  getChartHeight(data: SomeHighchartsOptions): number {
    const height = data.categoryLabels.length * 40;
    this.height = height < this.height ? this.height : height;
    if (this.maxHeight && this.height > this.maxHeight) {
      this.height = this.maxHeight;
    }

    return this.height;
  }
}
