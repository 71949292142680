/* eslint-disable max-lines-per-function */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { Aggregation } from '@shared/data-access/reports';
import { deepAssign, TotalLabel, ucFirst } from '@shared/util/code';
import * as Highcharts from 'highcharts';
import { PlotOptions, SeriesOptionsType, XAxisOptions, YAxisOptions } from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { SomeHighchartsOptions } from '../core/high-chart-data.service';
import { HighchartsThemeService } from '../core/highcharts-theme.service';
import { hasAnimation, HIGHCHARTS } from '../index';

@Component({
  selector: 'lsu-charts-column-bar',
  templateUrl: './column-bar-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HighchartsThemeService],
  standalone: true,
  imports: [HighchartsChartModule, TranslocoModule],
})
export class ColumnBarChartComponent {
  chartOptions: Highcharts.Options = {} as Highcharts.Options;
  highcharts: typeof Highcharts = HIGHCHARTS;
  updateFlag = false;
  chart: Highcharts.Chart = {} as Highcharts.Chart;
  height = 600;
  remark!: string;

  @Input() maxHeight: number = null!;

  @Input() set print(isPrint: boolean) {
    this._print = isPrint;
  }

  @Input() set data(data: SomeHighchartsOptions | undefined) {
    if (data) {
      this.remark = data.remark;
      this.setChartOptions(data);
    }
  }

  @Output() rendered: EventEmitter<Highcharts.Chart> = new EventEmitter<Highcharts.Chart>();

  private _print = false;

  constructor(
    highchartsThemeService: HighchartsThemeService,
    private translocoService: TranslocoService,
  ) {
    highchartsThemeService.applyThemeOptions();
    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
    });
  }

  onRender(chart: Highcharts.Chart) {
    this.chart = chart;
    this.rendered.emit(chart);
  }

  setChartOptions(data: SomeHighchartsOptions) {
    this.chartOptions.chart = {
      height: data.inverted ? 160 + data.series.length * 50 : 600,
      inverted: data.inverted,
    };

    this.chartOptions.xAxis = deepAssign<XAxisOptions, XAxisOptions>(this.xAxis(data.series.map((x) => x.name)), data.xAxis || {});
    this.chartOptions.yAxis = deepAssign<YAxisOptions, YAxisOptions>(this.yAxis(data.aggregation, data.min, data.max), data.yAxis || {});
    this.chartOptions.series = data.categoryLabels.map((x, i) => ({
      type: data.chartType,
      showInLegend: true,
      name: x,
      data: data.series.map((y) => y.data[i]),
      color: data.series.map((y) => y.data[i]).length ? data.series.map((y) => y.data[i])[0].color || undefined : undefined,
      animation: hasAnimation(this._print, Highcharts.getOptions().plotOptions?.series?.animation),
    })) as SeriesOptionsType[];

    const tooltip = data.tooltip || {
      headerFormat: undefined,
      pointFormat: `<span style="color:{series.color}">●</span> {series.name}: <b>{point.y:.${data.decimals}f}</b> <span>(n={point.n:.0f})</span> `,
    };

    if (data.chartType === 'column') {
      this.chartOptions.plotOptions = {
        column: { stacking: data.stacking, dataLabels: { enabled: true, format: `{y:.${data.decimals}f}` } },
      } as PlotOptions;
    } else if (data.chartType === 'bar') {
      this.chartOptions.plotOptions = {
        bar: { stacking: data.stacking, dataLabels: { enabled: true, format: `{y:.${data.decimals}f}` } },
      } as PlotOptions;
    } else if (data.chartType === 'line') {
      this.chartOptions.plotOptions = {
        line: { dataLabels: { enabled: true, format: `{y:.${data.decimals}f}` } },
      };
    }

    if (data.legend) {
      this.chartOptions.legend = data.legend;
    }

    this.chartOptions.exporting = {
      enabled: (data.exporting || data.exporting === undefined) && !this._print,
    };

    if (data.caption) {
      this.chartOptions.caption = data.caption;
    }
    if (data.colors) {
      this.chartOptions.colors = data.colors;
    }

    this.chartOptions.tooltip = tooltip;
    this.chartOptions.title = typeof data.title === 'string' ? { text: data.title } : data.title;
    this.chartOptions.subtitle = typeof data.subtitle === 'string' ? { text: data.subtitle } : data.subtitle;

    setTimeout(() => (this.updateFlag = true), 1000);
  }

  private xAxis(categories: string[]): XAxisOptions {
    categories = categories.map((x) =>
      x.split(' ').includes(TotalLabel) ? x.replace(TotalLabel, ucFirst(this.translocoService.translate('reports.total'))) : x,
    );
    return {
      categories,
    };
  }

  private yAxis(aggregation: Aggregation, min: number | null | undefined, max: number | null | undefined): YAxisOptions {
    return {
      min: min,
      max: max,
      tickInterval: 10,
      title: {
        text:
          aggregation === Aggregation.Average
            ? ucFirst(this.translocoService.translate('reports.average_amount'))
            : aggregation === Aggregation.Sum || aggregation === Aggregation.Count
              ? ucFirst(this.translocoService.translate('reports.total_amount'))
              : ucFirst(this.translocoService.translate('reports.total_percentage')),
      },
      labels: {
        format: aggregation !== Aggregation.None ? '{value}' : '{value}%',
      },
    };
  }
}
