import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { loginUrl, storeCurrentUrl } from '@shared/feature/auth';
import { UserService } from '@shared/util/user';
import { RoleFilterService } from './role-filter.service';
import { getMonitorType } from './translations/get-questionnaire-types';
import { Monitor, QuestionnaireType } from './webapi';

@Injectable({
  providedIn: 'root',
})
export class ReportAccessGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private reportDefaultFilterService: RoleFilterService,
  ) {}

  async canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isAuthenticated = this.userService.currentOrImpersonateUser.isAuthenticated;
    if (isAuthenticated) {
      return true;
    }
    // Store the userRequestUrl to return after the login is done!
    storeCurrentUrl(state.url);
    return this.router.parseUrl(loginUrl);
  }

  async canActivateChild(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(activatedRouteSnapshot, state);
  }

  async canAccessQuestionnaire(questionnaireType: QuestionnaireType): Promise<boolean> {
    const monitorType = getMonitorType(questionnaireType);
    if (!this.reportDefaultFilterService.userPoi) {
      await this.reportDefaultFilterService.resolve();
    }
    return !(
      (!this.reportDefaultFilterService.userPoi?.showBookStart && monitorType === Monitor.BookStart) ||
      (!this.reportDefaultFilterService.userPoi?.showPo && monitorType === Monitor.Po) ||
      (!this.reportDefaultFilterService.userPoi?.showVo && monitorType === Monitor.Vmbo)
    );
  }
}
