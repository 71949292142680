<highcharts-chart
  [Highcharts]="highcharts"
  [options]="chartOptions"
  (chartInstance)="onRender($event)"
  [(update)]="updateFlag"
  [oneToOne]="true"
  class="w-100 d-block"
></highcharts-chart>

<div class="d-flex justify-content-center">
  <div [innerHtml]="remark"></div>
</div>
