import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HIGHCHARTS, HighchartsThemeService } from '@shared/ui/charts';
import * as Highcharts from 'highcharts';
import { XAxisOptions, YAxisOptions } from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { getColorScheme } from '../color-scheme';
import { SomeHighchartsOptions } from '../highchart-data.service';

@Component({
  selector: 'pma-report-chart-bar',
  templateUrl: './chart-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HighchartsThemeService],
  standalone: true,
  imports: [HighchartsChartModule],
})
export class ChartBarComponent {
  readonly chartOptions: Highcharts.Options;
  readonly highcharts: typeof Highcharts = HIGHCHARTS;
  updateFlag = false;
  chart?: Highcharts.Chart;
  height = 600;
  remark!: string;

  @Input() maxHeight?: number;

  @Input() set print(isPrint: boolean) {
    this._print = isPrint;
  }

  @Input() set data(data: SomeHighchartsOptions) {
    this.remark = data.remark;
    this.setChartOptions(data);
  }

  @Input() set reflow(trigger: boolean) {
    if (this.chart) {
      this.chart.reflow();
    }
  }

  @Output() rendered: EventEmitter<Highcharts.Chart> = new EventEmitter<Highcharts.Chart>();
  private _print = false;

  constructor(highchartsThemeService: HighchartsThemeService) {
    highchartsThemeService.applyThemeOptions();
    this.chartOptions = {
      chart: {
        type: 'bar',
      },
      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'bottom',
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend?.backgroundColor || '#FFFFFF',
        shadow: true,
      },
      plotOptions: {
        bar: {
          showInLegend: true,
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
    };
  }

  onRender(chart: Highcharts.Chart) {
    this.chart = chart;
    this.rendered.emit(chart);
  }

  setChartOptions(data: SomeHighchartsOptions) {
    this.chartOptions.chart!.height = this.getChartHeight(data);
    this.chartOptions.xAxis = this.xAxis(data.categoryLabels);
    this.chartOptions.yAxis = this.yAxis(data.series[0].n, data.series.length === 1);
    this.chartOptions.legend!.enabled = data.series.length > 1;

    this.chartOptions.series = data.series.map((x) => ({ ...x, type: 'bar', animation: this._print ? false : true }));
    this.chartOptions.exporting = {
      enabled: this._print ? false : true,
    };
    this.chartOptions.title = { text: data.title };
    this.chartOptions.subtitle = { text: data.subtitle };
    // Prevent labels from being hidden
    if (data.categoryLabels.length > 6 && data.series.length > 2) {
      const labelOptions = this.chartOptions.plotOptions?.bar?.dataLabels as Highcharts.PlotBarDataLabelsOptions;
      labelOptions.allowOverlap = true;
      labelOptions.style = { fontSize: '10px' };
    }
    const series = this.chartOptions.series;

    this.highcharts.getOptions().colors = getColorScheme(series);
    this.updateFlag = true;
  }

  xAxis(categories: string[]): XAxisOptions {
    return {
      categories,
      title: {
        text: null,
      },
    };
  }

  yAxis(n: number, showN: boolean): YAxisOptions {
    return {
      min: 0,
      title: {
        text: showN ? `n = ${n}` : '',
        align: 'high',
      },
      labels: {
        overflow: 'justify',
        format: '{value}%',
      },
    };
  }

  getChartHeight(data: SomeHighchartsOptions): number {
    const height = data.categoryLabels.length * data.series.length * 20;
    this.height = height < this.height ? this.height : height;
    if (this.maxHeight && this.height > this.maxHeight) {
      this.height = this.maxHeight;
    }

    return this.height;
  }
}
